import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { SplashScreenComponent } from './layouts/splash-screen/splash-screen.component';
import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './layouts/loader/loader.component';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { GetObjectPipe } from './pipes/get-object.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TimeElapsedPipe } from './pipes/time-elapsed.pipe';
import { ColFilterDirective, ColFilterNameDirective } from './directives/col-filter.directive';
import { NgxSelectModule } from 'ngx-select-ex';
import { SwitchTabDirective } from './directives/switch-tab.directive';
import { OrderStatisticsComponent } from './chart/order-statistics/order-statistics.component';
import { OrderStatisticsCustomerLeftComponent } from './chart/order-statistics-customer-left/order-statistics-customer-left.component';
import { OrderStatisticsCustomerRightComponent } from './chart/order-statistics-customer-right/order-statistics-customer-right.component';
import { ChartStockMonitoringComponent } from './chart/chart-stock-monitoring/chart-stock-monitoring.component';
import { IntegerOnlyDirective } from './directives/integer-only.directive';
import { EnglishOnlyDirective } from './directives/english-only.directive';
import { GrdFilterPipe } from './pipes/grid-table-filter.pipe';
import { MaterialModule } from '../material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EnglishUpperCaseOnlyDirective } from './directives/english-uppercase-only.directive';
import { MobileFormatDirective } from './directives/mobile-format.directive';
import { FaxFormatDirective } from './directives/fax-format.directive';
import { CsChartComponent } from './chart/cs-chart/cs-chart.component';
import { BankOrderChartComponent } from './chart/bank-order-chart/bank-order-chart.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NotThaiCharDirective } from './directives/not-thai-char.directive';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { StorePieDashboardComponent } from './chart/store-pie-dashboard/store-pie-dashboard.component'; 
import { AutofocusDirective } from './directives/autofocus.directive'; 
import { TerminalIdDirective } from './directives/T_id.directive'; 
import { FilterPipe } from './directives/filter.directive';
import { CallHistoryModalComponent } from '../features/user/help-desk/shared/_modal/call-history/call-history.component';
import { FormatTotalHourTimePipe } from './pipes/format-total-hour-time.pipe';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
@NgModule({
  declarations: [
    SplashScreenComponent,
    LoaderComponent,
    //Pipes
    FirstLetterPipe,
    GetObjectPipe,
    SafePipe,
    TimeElapsedPipe,
    GrdFilterPipe,
    ColFilterDirective,
    ColFilterNameDirective,
    AutofocusDirective,
    SwitchTabDirective,
    OrderStatisticsComponent,
    OrderStatisticsCustomerLeftComponent,
    OrderStatisticsCustomerRightComponent,
    ChartStockMonitoringComponent,
    IntegerOnlyDirective,
    EnglishOnlyDirective,
    TerminalIdDirective,
    EnglishUpperCaseOnlyDirective, 
    MobileFormatDirective,
    FaxFormatDirective,
    CsChartComponent,
    BankOrderChartComponent,
    NotThaiCharDirective,
    KeepHtmlPipe,
    StorePieDashboardComponent, 
    FilterPipe,
    CallHistoryModalComponent,
    FormatTotalHourTimePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    MatProgressSpinnerModule,
    MatSortModule,
    MaterialModule,
    NgbModule,
    NgxPermissionsModule,
    TranslateModule,
    // MatChipsModule,
    // MatAutocompleteModule
  ],
  exports: [
    // MatAutocompleteModule,
    // MatChipsModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModalModule,
    SplashScreenComponent,
    LoaderComponent,
    MatSortModule,
    NgxSelectModule,
    MaterialModule,
    NgbModule,
    NgxPermissionsModule,
    //Directive
    ColFilterDirective,
    ColFilterNameDirective,
    AutofocusDirective,
    SwitchTabDirective,
    IntegerOnlyDirective,
    EnglishOnlyDirective,
    EnglishUpperCaseOnlyDirective, 
    MobileFormatDirective,
    FaxFormatDirective,
    NotThaiCharDirective,
    TerminalIdDirective,
    
    //Pipes
    FirstLetterPipe,
    GetObjectPipe,
    SafePipe,
    TimeElapsedPipe,
    GrdFilterPipe,
    KeepHtmlPipe,
    FilterPipe,
    FormatTotalHourTimePipe,

    // Components Chart
    OrderStatisticsComponent,
    OrderStatisticsCustomerLeftComponent,
    OrderStatisticsCustomerRightComponent,
    ChartStockMonitoringComponent,
    CsChartComponent,
    BankOrderChartComponent,
    StorePieDashboardComponent, 

    CallHistoryModalComponent
  ], 
  entryComponents:[CallHistoryModalComponent]
})
export class SharedModule { }
