import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../utils/date-utils';

@Pipe({
  name: 'formatTotalHourTime',
})
export class FormatTotalHourTimePipe implements PipeTransform {
  transform(value: number | null, divider: string | undefined = undefined, isSecond: boolean = false): string {
    if (value === null || value === undefined) value = 0;
    return DateUtils.formatTotalHourTime(value, divider, isSecond);
  }
}
