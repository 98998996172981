import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUtils {

  static getFileName(fileName: string) : string{
    const ext = fileName.substring(fileName.lastIndexOf('.')+1);
    return ext;
  } 

  static downloadFile(url:string,download: boolean = false) {
    let downloadUrl :string = url
    if(download){
      downloadUrl = downloadUrl +'?download=true'
    }
    var win = window.open(downloadUrl, '_blank')!;
    win.focus(); 
  }
}
